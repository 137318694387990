"use strict";

function backToTop(event) {
  document.body.scrollTop = 0;
  document.documentElement.scrollTop = 0;
  event.preventDefault();
}

function docReady(fn) {
  if (document.readyState === "complete" || document.readyState === "interactive") {
    setTimeout(fn, 1);
  } else {
    document.addEventListener("DOMContentLoaded", fn);
  }
}
/** Dropdown */


function initDropdownToggle(id) {
  var dropdown = document.querySelector(id);
  var dropdownBtn = dropdown.querySelector('.dropdown-toggle');
  var dropdownMenu = dropdown.querySelector('.dropdown-menu');
  dropdownBtn.addEventListener('click', function () {
    dropdownMenu.classList.toggle('hide');
    console.log('hello')
  });
  var dropdownItemList = dropdownMenu.querySelectorAll('.dropdown-item');

  for (var i = 0; i < dropdownItemList.length; i++) {
    dropdownItemList[i].addEventListener('click', function () {
      dropdownMenu.classList.toggle('hide');
    });
  }

  document.addEventListener('click', function (e) {
    if (dropdownBtn === e.target) {
      return;
    }

    if (!dropdownMenu.classList.contains('hide')) {
      dropdownMenu.classList.add('hide');
    }
  });
}

function loadGallery(element) {
  var modal = document.getElementById("galleryModal");
  var imgThumbnail = document.getElementById("imgThumbnail");
  modal.style.display = "flex";
  imgThumbnail.src = element.src;
}

;

function closeModal() {
  var modal = document.getElementById("galleryModal");
  modal.style.display = "none";
}

;
var swiper = new Swiper('.swiper-container', {
  slidesPerView: 1,
  spaceBetween: 30,
  loop: true,
  pagination: {
    el: '.swiper-pagination',
    clickable: true
  },
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev'
  },
  autoplay: {
    delay: 10000
  }
}); // Modal

// var modal = document.getElementById("myModal");
// var btn = document.getElementById("myBtn");
// var span = document.getElementsByClassName("close")[0];

// btn.onclick = function () {
//   modal.style.display = "flex";
//   document.body.style.overflow = "hidden";
// };

// span.onclick = function () {
//   modal.style.display = "none";
//   document.body.style.overflow = "scroll";
// };

// window.onclick = function (event) {
//   if (event.target == modal) {
//     modal.style.display = "none";
//     document.body.style.overflow = "scroll";
//   }
// }; 
// slider 


function initUniqueSlider(id) {
  let timer;
  if (id) {
    var mainEle = document.querySelector(id);

    if (!mainEle) {
      return;
    }

    var nextEle = mainEle.querySelector('.unique-next');
    var prevEle = mainEle.querySelector('.unique-previous');
    var imageEles = mainEle.querySelectorAll('.unique-image');
    var titleEles = mainEle.querySelectorAll('.unique-title');
    var contentEles = mainEle.querySelectorAll('.unique-content');

    var moveNext = function moveNext(e) {
      // Image
      var currentImage = mainEle.getElementsByClassName('unique-image show')[0];
      var nextImage = currentImage.nextElementSibling;

      if (nextImage) {
        currentImage.classList.remove('show');
        nextImage.classList.add('show');
      } else {
        currentImage.classList.remove('show');
        imageEles[0].classList.add('show');
      } // Title


      var currentTitle = mainEle.getElementsByClassName('unique-title show')[0];
      var nextTitle = currentTitle.nextElementSibling;

      if (nextTitle) {
        currentTitle.classList.remove('show');
        nextTitle.classList.add('show');
      } else {
        currentTitle.classList.remove('show');
        titleEles[0].classList.add('show');
      } // Title


      var currentContent = mainEle.getElementsByClassName('unique-content show')[0];
      var nextContent = currentContent.nextElementSibling;

      if (nextContent) {
        currentContent.classList.remove('show');
        nextContent.classList.add('show');
      } else {
        currentContent.classList.remove('show');
        contentEles[0].classList.add('show');
      }
      clearInterval(timer);
      timer = setInterval(moveNext, 5000);
    };

    var movePrev = function movePrev(e) {
      // Image
      var currentImage = mainEle.getElementsByClassName('unique-image show')[0];
      var previousImage = currentImage.previousElementSibling;

      if (previousImage) {
        currentImage.classList.remove('show');
        previousImage.classList.add('show');
      } else {
        currentImage.classList.remove('show');
        imageEles[imageEles.length - 1].classList.add('show');
      } // Title


      var currentTitle = mainEle.getElementsByClassName('unique-title show')[0];
      var previousTitle = currentTitle.previousElementSibling;

      if (previousTitle) {
        currentTitle.classList.remove('show');
        previousTitle.classList.add('show');
      } else {
        currentTitle.classList.remove('show');
        titleEles[titleEles.length - 1].classList.add('show');
      } // Content


      var currentContent = mainEle.getElementsByClassName('unique-content show')[0];
      var previousContent = currentContent.previousElementSibling;

      if (previousContent) {
        currentContent.classList.remove('show');
        previousContent.classList.add('show');
      } else {
        currentContent.classList.remove('show');
        contentEles[contentEles.length - 1].classList.add('show');
      }
      clearInterval(timer);
      timer = setInterval(moveNext, 5000);
    };

    nextEle.addEventListener('click', moveNext);
    prevEle.addEventListener('click', movePrev);
    timer = setInterval(moveNext, 5000);
  }
} // Contact form


var isCaptchaValidated = false;

function captchaCallBack(response) {
  isCaptchaValidated = true;
}

function captchaExpiredCallback() {
  isCaptchaValidated = false;
}

function sendEmailContactForm(id) {
  var contactForm = document.querySelector(id);
  var name = contactForm.querySelector('#name').value;
  var email = contactForm.querySelector('#email').value;
  var phone = contactForm.querySelector('#phone').value;
  var content = contactForm.querySelector('#content').value;

  var sendEmail = function sendEmail() {
    var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    var btnSend = contactForm.querySelector('#btnSend');
    btnSend.classList.add('disabled');
    var http = new XMLHttpRequest();
    var url = 'https://271dev.com/send';
    http.open('POST', url, true); //Send the proper header information along with the request

    http.setRequestHeader('Content-type', 'application/json');

    http.onreadystatechange = function () {
      //Call a function when the state changes.
      if (http.readyState === 4 && http.status === 200) {
        contactForm.querySelector('#name').value = '';
        contactForm.querySelector('#email').value = '';
        contactForm.querySelector('#phone').value = '';
        contactForm.querySelector('#content').value = '';
        contactForm.querySelector('.name-error').classList.remove('show');
        contactForm.querySelector('.email-error').classList.remove('show');
        contactForm.querySelector('.phone-error').classList.remove('show');
        contactForm.querySelector('.captcha-error').classList.remove('show');
        contactForm.querySelector('.error-message').classList.remove('show');
        btnSend.classList.remove('disabled');
        var successMessage = contactForm.querySelector('.success-message');
        successMessage.classList.add('show');
      }

      if (http.readyState === 4 && http.status > 300) {
        contactForm.querySelector('.error-message').classList.add('show');
        btnSend.classList.remove('disabled');
      }
    };

    http.send(JSON.stringify(params));
  };

  var isValid = true;
  var emailRe = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
  var phoneRe = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
  var isValidEmail = emailRe.test(email) && email;
  var isValidPhone = phoneRe.test(phone) && phone;

  if (!name) {
    isValid = false;
    contactForm.querySelector('.name-error').classList.add('show');
  } else {
    contactForm.querySelector('.name-error').classList.remove('show');
  }

  if (!isValidEmail) {
    isValid = false;
    contactForm.querySelector('.email-error').classList.add('show');
  } else {
    contactForm.querySelector('.email-error').classList.remove('show');
  }

  if (!isValidPhone) {
    isValid = false;
    contactForm.querySelector('.phone-error').classList.add('show');
  } else {
    contactForm.querySelector('.phone-error').classList.remove('show');
  }

  if (!isCaptchaValidated) {
    contactForm.querySelector('.captcha-error').classList.add('show');
    isValid = false;
  } else {
    contactForm.querySelector('.captcha-error').classList.remove('show');
  }

  if (!isValid) {
    return;
  }

  var body = "Kh\xE1ch h\xE0ng c\xF3 t\xEAn: ".concat(name, ".<br />\n      T\u1EEB \u0111\u1ECBa ch\u1EC9 email: ").concat(email, " <br />\n      S\u1ED1 \u0111i\u1EC7n tho\u1EA1i: ").concat(phone, " <br />\n      C\xF3 m\u1ED9t tin nh\u1EAFn v\u1EDBi n\u1ED9i dung: ").concat(content);
  var sendingParams = {
    to: 'tuvan@tranduongco.com',
    subject: '[No Reply]New message from customer',
    body: body
  };
  sendEmail(sendingParams);
}

docReady(function () {
  initDropdownToggle('#languageDropdown');
  initUniqueSlider('#introductionUniqueSlider');
  
});


var isMobile = window.outerWidth <= 768;

$(document).ready(function(){
  $('.td-our-projects').slick({
    slidesToShow: isMobile ? 1 : 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 10000
  });
  $('.td-staffs').slick({
    slidesToShow: isMobile ? 1 : 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 10000
  });
  $('.td-products').slick({
    slidesToShow: isMobile ? 1 : 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 10000
  });
});
